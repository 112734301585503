.form-control.ld {
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 12%);
  min-height: 40px;

  &.trans-border {
    border-color: transparent;
  }
}

.ng-select.ld {
  .ng-select-container {
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 12%) !important;
    min-height: 40px !important;
  }


  &.trans-border {
    .ng-select-container {
      border-color: transparent !important;
    }
  }
}