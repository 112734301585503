@import '../../style/abstracts/variables';
@import '../../style/abstracts/mixins';

$border-child: #e6edf1;
$item-height: 45px;

.mobile-drawer-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .menu-backdrop {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: var(--x-text-color);
    opacity: 0;
    z-index: $zindex-fixed;
    &.open {
      left: 0;
      opacity: 0.5;
    }
  }

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: -100%;
    width: 80%;
    top: 0;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    z-index: $zindex-fixed;
    background-color: white;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;

    &.open {
      left: 0;
    }

    .menu {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      height: 100%;

      .group-menu {
        border-bottom: unset;
        padding-bottom: 0;
        margin-bottom: 0;

        .menu-item {
          background-color: white;
          .item {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            padding: 15px;
            white-space: nowrap;
            overflow: hidden;
            height: $item-height;

            &.child {
              margin-left: 15px;
              padding-left: 19px;
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;

              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 3px;
                bottom: 0;
                width: 0px;
                background-color: $border-child;
              }
              .collapse-icon {
                display: none;
              }
            }

            &:hover {
              background-color: $row-hover-color;
              &::before {
                background-color: $secondary;
                opacity: 0.3;
                width: 2px;
              }
            }

            &.active {
              .label,
              .icon,
              .fa {
                color: $primary;
              }

              &:not(.child),
              &.child.hover-item {
                background-color: $bg-gray;
              }

              &::before {
                background-color: $primary;
                opacity: 1;
                width: 2px;
              }
            }

            .label {
              color: $secondary;
              font-size: 1rem;
              margin-left: 20px;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .icon {
              width: 20px;
              text-align: center;

              .request-number {
                right: -7px;
              }
            }

            .collapse-icon {
              top: 17px;
            }
          }

          .collapse {
            border-top-color: transparent;

            .collapse {
              border: 0;
            }
          }
        }
      }
    }
  }
}
