@import '../../style/abstracts/variables';

.tooltip {
  .arrow {
    display: none;
  }

  &.avatar-tooltip .tooltip-inner {
    background-color: white;
    color: $body-color;
    border: $form-border;
    max-width: unset;
    font-size: 1rem;
    .unassigned {
      @include user-avatar($icon-md);
      font-size: 0.7rem;
    }
  }

  &.light-tooltip .tooltip-inner {
    background-color: white;
    color: $body-color;
    max-width: unset;
    font-size: 1rem;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(29, 88, 166, 0.16);
    border: solid 0.8px var(--x-border-secondary);
  }

  &.large-tooltip .tooltip-inner {
    z-index: 100;
    max-width: 400px;
    min-width: 200px;
    background-color: white;
    color: $secondary;
    border: 1px solid $light-blue;
    box-shadow: 0 3px 6px 0 $border-color;
    text-align: left;
  }

  &.block-tooltip .tooltip-inner {
    min-width: 400px;
    max-height: 400px;
    overflow: hidden;
    background-color: $bg-gray;
    color: $secondary;
    border: 1px solid $light-blue;
    box-shadow: 0 3px 6px 0 $border-color;
    text-align: left;
  }
  &.none-tooltip .tooltip-inner {
    display: none;
  }

  &.show {
    opacity: 1;
  }
}

.popover {
  background-color: white;
  max-width: unset;
  width: fit-content;
  .arrow {
    display: none;
  }
}
