/* You can add global styles to this file, and also import other style files */

@tailwind components;
@tailwind utilities;

@import './style/main.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.custom-invalid-feedback {
  span {
    font-size: 12px;
    color: red;
    opacity: 0.75;
  }
}

app-user-avatar {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.d-flex-center {
  display: flex;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

hr {
  border-top: 1px solid $border-color;
}

.lh-input {
  line-height: $inputHeight;
}

.landing-page-wrapper {
  @include landing-page();
}

.mention {
  border-radius: 16px;
  color: $primary;
  user-select: all;
  cursor: pointer;
  > span {
    margin: 0 6px;
  }

  &[complete='true'] {
    padding: 2px 6px;
    background-color: $primary;
    color: white;
    -webkit-user-modify: read-only;
  }
}

.as-split-gutter {
  background-color: transparent !important;
  flex-basis: 25px !important;
  .as-split-gutter-icon {
    background-image: unset !important;
    width: 2px !important;
  }

  &:hover,
  &.as-dragged {
    .as-split-gutter-icon {
      transition: all 0.3ms ease-in 0s;
      background-color: #2196f3;
    }
  }
}

.task-drag-drop-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px dashed transparent;

  &.dragging {
    border-color: #0053b3;
    background: #dde8f1;
    opacity: 0.5;
    z-index: 99;
  }
}

.bg-white {
  background: white;
}

img.project-logo {
  border-radius: 3px;
}

img.icon-sm,
.mat-icon.icon-sm,
div.icon-sm {
  width: $icon-sm;
  height: $icon-sm;
  min-width: $icon-sm;
  min-height: $icon-sm;
}

img.icon-md,
.mat-icon.icon-md,
div.icon-md {
  width: $icon-md;
  height: $icon-md;
  min-width: $icon-md;
  min-height: $icon-md;
}

span.icon-md {
  font-size: $icon-md;
}

img.icon-lg,
.mat-icon.icon-lg {
  width: $icon-lg;
  height: $icon-lg;
  min-width: $icon-lg;
  min-height: $icon-lg;
}

span.icon-lg {
  font-size: $icon-lg;
}

img.icon-xlg,
.mat-icon.icon-xlg {
  width: $icon-xlg;
  height: $icon-xlg;
  min-width: $icon-xlg;
  min-height: $icon-xlg;
}

span.icon-xlg {
  font-size: $icon-xlg;
}

.spinner-border-lg {
  width: 3rem;
  height: 3rem;
}

.rotate-left-45 {
  transform: rotate(-45deg);
}

.request-status {
  font-weight: 500;

  &.waiting {
    color: $primary;
  }

  &.approved {
    color: $success;
  }

  &.rejected {
    color: $red;
  }

  &.cancelled {
    color: $yellow;
  }

  &.approved-cancellation {
    color: $success;
  }
}

.leave-status-circle {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  background-color: silver;
}

.notification-number {
  background-color: #cd2453;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  color: white;
  font-size: 12px;
}

.toggle {
  .toggle-header {
    display: flex;
    align-items: center;
    font-weight: 500;

    .arrow-down {
      width: 19px;
      max-width: 19px;
      margin-left: auto;
      transition: all 0.3s ease;

      &.expand {
        transform: rotate(180deg);
      }
    }
  }
}

.switch-wrapper {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  width: max-content;
  max-width: 100%;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  height: $inputHeight;
  background-color: #f4f5f7;

  .switch-button {
    cursor: pointer;
    padding: 3px 5px;
    border-radius: 3px;
    text-align: center;
    min-width: 35px;

    &.active {
      background-color: white;
      cursor: default;
      box-shadow: rgb(13 20 36 / 18%) 0px 3px 5px,
        rgb(13 20 36 / 29%) 0px 0px 1px !important;
    }
  }
}

.editable-control {
  border-color: transparent;
  position: relative;
  transition: all 100ms ease-in 0s;
  background-image: unset;
  background-position: right 0px top 25%;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1.75rem;

  &:hover {
    border-color: $form-border-color;
    background-image: url('./assets/icons/edit.svg');
    background-color: transparent;
    cursor: pointer;

    &.add-control {
      background-image: url('./assets/icons/add_circle_outline_primary.svg');
    }
  }

  &:focus {
    background-image: unset;
    background-color: white;
    border-color: $primary;
    cursor: text;
  }

  &.form-control {
    background-position: right 4px top 7px;
    &:hover {
      background-color: rgba(0, 73, 118, 0.02);
    }
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 2.5rem 2.5rem;
  }
}

.percent-group {
  display: flex;
  align-items: center;
  width: 100%;

  .percent-line {
    width: 100%;
    height: 1rem;
    border-radius: 1rem;
    background-color: #e8e9ed;

    .percent {
      height: 100%;
      border-radius: 1rem;
      background-color: #34a975;

      &.inprogress {
        background-color: #57aed1;
      }

      &.striped {
        background-image: linear-gradient(
          315deg,
          rgba(255, 255, 255, 0.15) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.15) 50%,
          rgba(255, 255, 255, 0.15) 75%,
          transparent 75%,
          transparent
        );
        background-size: 2.5rem 2.5rem;
      }

      &.animated {
        animation: move 2s linear infinite;
      }
    }
  }

  .percent-number {
    min-width: 42px;
    width: 42px;
    text-align: right;
  }

  &.small-line {
    .percent-line {
      height: 10px;
      border-radius: 10px;
    }

    .percent {
      border-radius: 10px;
    }
  }
}

.mobile-wrapper {
  .table-wrapper {
    .header-item {
      display: flex;
      border-bottom: 1px solid #dde8f1;
      padding-left: 4px;
      padding-right: 4px;

      .start-col {
        flex: auto;
      }
      .end-col {
        width: fit-content;
      }
    }
    .body-item {
      display: flex;
      border-bottom: 1px solid #dde8f1;

      .start-col {
        flex: auto;
        overflow: hidden;
      }
      .end-col {
        width: fit-content;
        .value {
          display: flex;
          height: 100%;
          align-items: center;

          .btn-item {
            height: 100%;

            button {
              height: 100%;
              padding-left: 1.25rem;
              padding-right: 1.25rem;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}
.mobile-wrapper {
  padding: 0;
  .main-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    .filter-container {
      display: flex;
      flex-direction: column-reverse;
    }
    .list-items {
      flex: 1;
      overflow-y: auto;
      padding: 0 8px;
      margin: 0 -8px;
      .content-wrap {
        display: flex;
        justify-content: space-between;
        .content {
          flex: 1;
          display: flex;
          align-items: center;
          .content-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            line-height: 18px;
          }

          .content-body {
            line-height: 18px;
            color: #6b778c;
            .reason {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .paging {
        .datatable-footer-inner {
          font-size: 12px;
        }
        .page-count {
          padding: 0 7px;
        }
      }
    }
  }
}

.search-size {
  font-size: 1.7rem;
}

//== Filter modal on mobile -> Will be removed
.filter-modal {
  .filter-modal-dialog {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
  }
  .close-btn {
    color: #42526e;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
  }
}

.mobile-modal .modal-open {
  .modal {
    overflow: hidden;
  }
}

.mobile-modal .modal {
  &.fade {
    .modal-dialog {
      transform: translate(0, 50px);
      transition: transform 0.1s ease-out;
    }
  }
  &.show {
    .modal-dialog {
      transform: none;
    }
  }
}
// ===

.auto-scroll {
  @include auto-scroll();
}

.empty-page-wrapper {
  .title-page {
    font-size: 1.2rem !important;
    color: $secondary;
    font-weight: 600 !important;
    margin-top: 21px !important;
  }

  .subtitle-page {
    font-size: 1rem !important;
    color: $secondary;
    font-weight: 400 !important;
    margin-top: 7px !important;
  }

  .img-empty {
    width: 280px !important;
  }

  .btn-create-empty {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
    padding: 10px 40px;
    font-size: 1rem !important;
  }
}

.ant-message .anticon {
  top: -1px !important;
}
.ant-message-notice-content {
  padding: 10px 10px;
  border-radius: 6px;
}

.spinner-loading-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &.grey-out {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: $zindex-fixed;
  }
}

.select-dropdown-menu {
  width: 220px;
  padding: 15px;
  padding-bottom: 8px;
  .select-lists {
    margin: 0 -15px;
    .select-item {
      padding: 8px 15px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: var(--x-option-hover);
      }
    }
  }
}

.drag-indicator.cdk-drag-preview {
  box-shadow: none !important;
}
