@import "../abstracts/variables";


.ql-picker.ql-table {

  .ql-picker-label {

    &::before {
      font-family: "Material Icons" !important;
    }
  }

  &:nth-of-type(1) {
    .ql-picker-label {
      &::before {
        content: "\e265" !important;
      }
    }
  }

  &:nth-of-type(2) {
    .ql-picker-label {
      &::before {
        content: "\f101" !important;
      }
    }
  }
}

// .ql-toolbar {
//   border-color: $border-color;
//   border-bottom-color: transparent !important;
//   border-radius: 0.25rem;
//   padding-top: 1rem;
//   padding-bottom: 1rem;
// }

// @media only screen and (max-width: 768px){
//   .ql-toolbar {
//     background-color: #f5f6f8;
//     padding: 8px;
//     padding-bottom: 8px;
//   }
// }

.ql-toolbar.ql-snow {
  .ql-picker-label {
    display: inline-flex;
  }
}

.ql-container {
  font-family: $font-family-base !important;
  font-size: $font-size-base !important;
  border-color: $border-color;

  &.ql-snow {
    .ql-editor {
      // white-space: normal !important;

      .placeholder {
        color: $logan;
      }
    }
  }

  .ql-editor {
    p {
      margin-bottom: 1rem !important;
    }

    img {
      max-height: 200px;
      padding-right: 0.5rem;
    }

    &.ql-blank::before {
      color: $logan !important;
      font-style: normal !important;
    }
  }
}

.ql-mention-list-container {
  min-width: 150px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: rgb(9 30 66 / 13%) 0px 0px 0px 1px, rgb(9 30 66 / 13%) 0px 4px 11px;
  z-index: 9001;
  overflow: auto;
  min-height: 50px;
  max-height: 185px;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 1.2rem
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden
}

.ql-mention-list-item {
  cursor: pointer;
  font-size: 1rem;
  padding: 8px 10px;
  vertical-align: middle
}

.ql-mention-list-item.disabled {
  cursor: auto
}

.ql-mention-list-item.selected {
  background-color: #ebf5ff;
  text-decoration: none
}


// .ql-editor-html-view {
//   p {
//     margin-bottom: 1rem !important;
//   }

//   img {
//     cursor: pointer;
//     max-height: 200px;
//     padding-right: 0.5rem;
//     // margin-bottom: 1rem;
//   }

//   &.ql-blank::before {
//     color: $logan !important;
//     font-style: normal !important;
//   }

//   &.ql-editor {
//     padding: 0;
//   }
// }