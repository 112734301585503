@import "../abstracts/variables";

.badge {
  font-size: 100%;
  font-weight: 500;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 0.8rem;
}

.badge-light {
  background-color: #dde8f1;
  color: $secondary;
}

.badge-success {
  color: white;
}

.badge-secondary {
  background-color: #737373;
}

.badge-label {
  color: white;
  padding: 0 0.5rem;
  background-color: #42526e;
  border-radius: 0.3rem;
}

.badge-group {
  background: linear-gradient(to bottom, #ff575e, #ff5f7f);
}

.badge-group-opacity {
  background: #f9e9eb;
}

.badge-team {
  background: linear-gradient(to bottom, #57b1ff, #5f89ff);
}

.badge-team-opacity {
  background: #E4F2E4;
}

.badge-group, .badge-team  {
  color: white;
  font-weight: normal;
  &.badge-main, ~.badge-suffix {
    border-radius: 0.25rem;
  }
}

.badge-group-opacity, .badge-team-opacity {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  &.badge-main, ~.badge-suffix {
    margin-left: 0rem !important;
    margin-right: 0.5rem !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem;
    border-radius: 3px;
    color: #42526e;
  }
}

.badge-platform {
  border-radius: 3px;
  background-color: #e1e4e8;
  padding-left: 5px;
  padding-right: 5px;
}
