// z-index froala has range from 2147483640 to 2147483647
$z-index-level1: 2147483640;
$z-index-level2: 2147483641;
$z-index-level3: 2147483642;
$z-index-level4: 2147483643;
$z-index-level5: 2147483644;
$z-index-level6: 2147483645;
$z-index-level7: 2147483646;
$z-index-level8: 2147483647;

.selected .task-item-wrapper {
  background-color: #f1f7ff !important;
}

.fr-box.fr-document .fr-wrapper {
  padding: 10px 30px;
  background-color: #fafbfc;
}

.fr-toolbar .fr-popup.fr-desktop {
  z-index: $z-index-level8 !important;
}

.fr-wrapper > div > a {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  background: white !important;
  overflow: hidden !important;;
}

.fr-fullscreen-wrapper {
  position:absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fr-popup.fr-desktop.fr-active {
  z-index: $z-index-level7 !important;
}

.fr-tooltip.fr-visible {
  z-index: $z-index-level8 !important;
}

.fr-popup-position-left-70 {
  .fr-popup.fr-desktop.fr-active {
    left: 70px !important;
  }
}

.fr-box.fr-basic .fr-element,
.fr-wrapper .fr-placeholder,
.fr-toolbar,
.fr-popup,
.fr-modal ,
.fr-tooltip {
  font-family: $font-family-base !important;
}
