.fade-out-bottom {
	-webkit-animation: fade-out-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: fade-out-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-29 15:46:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
}
