@import "../../style/abstracts/variables";

.auth-wrapper {
  display: flex;

  .bold-primary {
    font-weight: bold;
    color: $primary;
  }

  .left-content {
    flex: 1;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    padding: 2rem 5rem;

    .title {
      margin-top: 5.5rem;
      font-size: 3.5rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      color: white;
    }
  }

  .right-content {
    display: flex;
    flex-direction: column;
    min-width: 46%;
    max-width: 860px;
    background-color: white;
    color: #737373;
    padding: 2rem 5rem;

    .company-logo {
      height: 3rem;
      margin-bottom: 2.5rem;
      @media (max-width: 991.98px) {
        height: 2rem;
      }
    }

    .form {
      .title {
        font-size: 3.5rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
        color: $primary;
        @media (max-width: 991.98px) {
          font-size: 1.8rem;
        }
      }

      .subtitle {
        font-size: 1rem;
        margin-bottom: 2rem;
      }

      .form-group {
        margin-bottom: 2rem;
        position: relative;
      }

      .btn-landing {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .auth-wrapper {
    .right-content {
      max-width: unset;
      width: 100%;
      padding: 2rem;
    }
  }
}

@media (max-width: 1200px) {
  .auth-wrapper {
    justify-content: center;

    .left-content {
      display: none;
    }
  }
}
