@import '@angular/cdk/overlay-prebuilt.css';
@import '../abstracts/variables';

$bg-tooltip-overlay: #e0f7ff;

.cdk-overlay-container {
  z-index: 99999;
}
.cdk-overlay-pane {
  &.work-calendar,
  &.work-calendar-overlay-upsert {
    max-height: unset;
    max-width: unset;
  }

  max-height: 300px;

  &.user-container {
    .avatar-wrapper {
      display: flex;
      &.unassigned {
        @include user-avatar($icon-md);
      }
      .unassigned-avatar {
        font-size: $icon-md;
      }
    }

    background-color: white;
    border: $form-border;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    border-radius: 3px;
    margin: 0.25rem 0;
  }

  &.description-container {
    > app-editor-html-view {
      background-color: $bg-gray;
      border: $form-border;
      overflow: hidden;
      max-width: 400px;
      min-width: 180px;
      max-height: 500px;
      padding: 10px;
      overflow: auto;
      border-radius: 3px;
    }
  }

  &.sprint-description-overlay {
    background-color: $bg-tooltip-overlay;
    padding: 8px 10px;
    max-width: 400px;
    overflow-y: auto;
    min-width: 120px;
    word-wrap: break-word;
    border-radius: 3px;
    margin-left: -14px;
    box-shadow: 0 3px 6px 0 rgba(29, 88, 166, 0.12);
  }
}

.watch-use-dialog {
  max-height: unset !important;
}
