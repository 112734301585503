@import "../abstracts/mixins";
@import "../abstracts/variables";

.app-avatar-wrapper {
  @include user-avatar($icon-md);

  .name {
    // FUA: update style for deprecation
    font-size: calc($icon-md/2);
  }

  .unassigned-avatar {
    font-size: $icon-md;
  }

  &.small {
    @include user-avatar($icon-sm);

    .name {
    // FUA: update style for deprecation
      font-size: calc($icon-sm/2);
    }

    .unassigned-avatar {
      font-size: $icon-sm;
    }
  }

  &.medium {
    @include user-avatar(24px);

    .name {
      font-size: 12px;
    }

    .unassigned-avatar {
      font-size: 24px;
    }
  }

  &.large {
    @include user-avatar($icon-lg);

    .name {
    // FUA: update style for deprecation
      font-size: calc($icon-lg/2);
    }

    .unassigned-avatar {
      font-size: $icon-lg;
    }
  }

  &.very-large {
    @include user-avatar($icon-xl);

    .name {
    // FUA: update style for deprecation
      font-size: calc($icon-xl/2);
    }

    .unassigned-avatar {
      font-size: $icon-xl;
    }
  }

}
